:root {
  --main-bg: #282c36;
  --sec-bg: #333742;
  --sec-alt-bg: #4e4e4e;
  --thi-bg: #dfdede;
  --fou-bg: #5b6275;
  --contrast-blue: 	#4a7b99;
  --prim-blue: 	#4a7b99;
  --sec-blue:#80bddf;
  --thi-blue: #e7f9ff;
  --m5-blue: #e4f2fa;
  --m4-blue: #b7dff6;
  --m3-blue:#9cc7df;
  --m2-blue: 	#96b4c5;
  --m1-blue: #658396;;
  --prim-purp: #ffd700;
  --sec-purp: rgb(255, 228, 75);
  --thi-purp:#ffed84;
  --alt-1: rgb(163, 73, 163);
  --alt-2: rgb(207, 125, 207);
}

.slider-beg::-moz-range-track {
  height: 40px;
  background: #ddd;
}

.slider-beg::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #999;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw var(--prim-blue);
  box-sizing: border-box;
}

.slider-beg::-ms-fill-lower { 
  background: var(--prim-blue);
}

.slider-beg::-ms-thumb { 
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

.slider-beg::-ms-ticks-after { 
  display: none; 
}

.slider-beg::-ms-ticks-before { 
  display: none; 
}

.slider-beg::-ms-track { 
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}

.slider-beg::-ms-tooltip { 
  display: none;
}

.slider-int::-moz-range-track {
  height: 40px;
  background: #ddd;
}

.slider-int::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #999;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw var(--prim-purp);
  box-sizing: border-box;
}

.slider-int::-ms-fill-lower { 
  background: var(--prim-purp);
}

.slider-int::-ms-thumb { 
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

.slider-int::-ms-ticks-after { 
  display: none; 
}

.slider-int::-ms-ticks-before { 
  display: none; 
}

.slider-int::-ms-track { 
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}

.slider-int::-ms-tooltip { 
  display: none;
}


.slider-exp::-moz-range-track {
  height: 40px;
  background: #ddd;
}

.slider-exp::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #999;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw var(--sec-bg);
  box-sizing: border-box;
}

.slider-exp::-ms-fill-lower { 
  background: var(--sec-bg);
}

.slider-exp::-ms-thumb { 
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

.slider-exp::-ms-ticks-after { 
  display: none; 
}

.slider-exp::-ms-ticks-before { 
  display: none; 
}

.slider-exp::-ms-track { 
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}

.slider-exp::-ms-tooltip { 
  display: none;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 368px;
  height: 2em;
  line-height: 3;
  background: white;
  overflow: hidden;
  border-radius: .25em;
  margin-bottom: 30px;
}

/* Arrow */
.select::after {
  content: '\25BC';
  color: white;
  position: absolute;
  right: 0;
  bottom: -10px;
  padding: 0 10px;
  background: var(--sec-bg);
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
/* Transition */
.select:hover::after {
  color: var(--prim-blue);
}

.select-error {
  color: white!important;
  background-color: rgb(211, 58, 58)!important;
}

/* Submit btn */

.resort-section {
  margin-left: 160px;
  margin-right: 160px;
  margin-top: 30px;
  padding-left: 10px;
}

.resort-header {
  display:flex;
  color:  var(--sec-bg);
  height: 75px;
  margin-bottom: 10px;
}

.resort-logo {
  height: 75px;
  margin-right: 10px;
}

.resort-header-title {
  font-size: 30px;
  height: 75px;
  background-color: var(--sec-blue);
  color:white;
  margin: 0!important;
  line-height: 75px;
  width: 100%;
  padding-left: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
}

.resort-header-title:hover {
  background-color: var(--prim-purp);
  color:var(--sec-bg);
  cursor: pointer;
}


.resort-desc-p {
  color:var(--sec-bg);
  
}

.resort-flag {
  width: auto;
  height: auto;
  max-height: 75px;
  margin-left: 5px;
}

/* parallax */


.parallax {
  height: 300px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
}

#content-mobile{
  display:none
}

#content-mobile-small {display:none}

@media screen and (max-width:1024px){
  #content-desktop{display:none}
  #content-mobile {display:flex;height:auto;width:100%}
  #content-mobile-small {display:none}
}

@media screen and (max-width:768px){
  #content-desktop{display:none}
  #content-mobile {display:flex;height:auto;width:98%}
  #content-mobile-small {display:none}
}


@media screen and (max-width:375px){
  #content-desktop{display:none}
  #content-mobile {display:none}
  #content-mobile-small {display:flex;height:auto;width:98%}
}

.parallax-img {
  height: 300px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


/* Metric cards */ 
.resort-content-container {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--sec-bg);
  color:white;
  width:100%;
  min-height: 10px;  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}



.resort-content-container-checked {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--sec-blue)!important;
  width:100%;
  min-height: 10px;  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

.resort-desc-container{
  width:90%;
  margin-top: 20px;
}
.resort-desc {
  font-size: 10px;
  margin-right: 10px;
  width:90%;
  margin-block-start: 0px!important;
}

.metric-header {
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 15px;
  color: white;
}


.metric {
  margin-right: 5px;
  min-width: 120px;
}

.metric-title {
  margin-top: 10px;
  margin-left: 2.5px;
  padding: 2px;
  margin-bottom:1px;
  font-size:12.5px;
  text-align: center;
  color: var(--sec-bg)
}

.metric-card {
  margin-left: 2.5px;
  padding: 2px;
  background-color: var(--sec-blue);
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  cursor:default;
  margin-bottom: 30px;
  padding: 5px;
}

.metric-card:hover{
  background-color: var(--prim-blue)
}

.metric-value {
  font-size: 12px;
  text-align: center;
}



.sweet-loading {
  display: block;
  margin: 0 auto;
  border-color: red;
}

/* chart & map */


.chart-section{
  padding-top: 10px;
  padding-left: 5px;
  align-items: center;
}

.graphContainer {
  height: 250px ;
  width: 95%;
}

.graphContainer-mini {
  height:200px ;
  margin-bottom: 150px;
  align-items: center;
}

.graph-helper-text {
  font-size: 9px!important;
  text-align:center;
  margin-bottom:30px;
}

.resort-info-container {
  margin-left: 10px;
}

.info-box {
  margin-left: 10px;
  margin-top: 10px;
}

.info-header {
  color: var(--sec-bg)
}

/* Book button */

.resort-content-container-button {
}

.button-container {
  display:flex;
}

.resort-book {
  width:50%;
  height: 50px;
  background-color: var(--sec-purp);
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  color:white;
  border: 0.1px solid white;
  font-size: 17.5px;
  margin-right: 10px;
  margin-left: 10px;
  cursor:pointer;
}

.resort-book:hover {
  background-color: var(--prim-purp);
}

.resort-list {
  width:50%;
  height: 50px;
  background-color: var(--sec-blue);
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  color:white;
  border: 0.1px solid white;
  font-size: 17.5px;
  margin-right: 10px;
  cursor:pointer;
}

.resort-list:hover{
  background-color: var(--prim-blue);
}

/* header */
.list-header {
  margin-top: 5px;
  display:flex;
  margin-left: 160px;
  margin-right: 160px;
  padding-left: 10px;
}

.list-separator {
  margin-top: 5px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--prim-purp);
  text-align:center;
}

.list-separator-text {
  font-size: 20px!important;
}

.site-logo-header{
  width: 90px;
  height: auto;
  background-color:var(--main-bg);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-right: 7.5px;
}

.site-logo-header-text{
    margin-block-start: 0!important;
    margin-block-end: 0!important;
    margin-inline-start: 0!important;
    margin-inline-end: 0!important;
    font-size: 15px!important;
    padding-left: 10px;
    padding-top: 2px;
    padding-right:10px;
    padding-bottom: 10px;
    color:white;
    background-color: var(--prim-blue);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    width: 100%;
    border-radius:5px;
    height:30px;
}

/* Pagination buttons */
.paginationBtn-container {
  position: fixed;
  display:flex;
  bottom: 60px;
  text-align: center;
}

.paginationLeft {
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border: 0.3em solid var(--sec-bg);
  border-radius: 50%;
  margin-right: 0.25em;
  background-color: white;
}

.paginationLeft:after {
  content: '';
	display: inline-block;
  margin-top: 0.65em;
  margin-left: 0.25em;
  width: 1em;
  height: 1em;
  border-top: 0.5em solid var(--sec-bg);
  border-right: 0.5em solid var(--sec-bg);
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.paginationLeft:hover {
  background-color: var(--prim-purp);
}

.paginationRight {
  width: 50px;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 0.3em solid var(--sec-bg);
  border-radius: 50%;
  margin-left: 0.25em;
  background-color: white;
}

.paginationRight:after{
  content: '';
	display: inline-block;
  margin-top: 0.65em;
  margin-left: -0.25em;
  width: 1em;
  height: 1em;
  border-top: 0.5em solid var(--sec-bg);
  border-right: 0.5em solid var(--sec-bg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.paginationRight:hover {
  background-color: var(--prim-purp);
}

.paginationText {
  display: inline-block;
  font-size: 15px;
  vertical-align: middle;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
  line-height: 50px;
  background-color: white; 
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}


/* sorts */
.header-sort{
  margin-left: 160px;
  margin-right: 160px;
  margin-top: 20px;
  padding-left: 10px;
  font-size: 15px;
  margin-block-end: 0;
  margin-block-start:1em;
  color:white;
}

.resort-sort {
  display:flex;
  margin-left: 160px;
  margin-right: 160px;
  flex-wrap:wrap;
  justify-content: space-evenly;
}

.btn-sort {
  border:none;
  cursor: pointer;
  height: 30px;line-height: 30px;
  margin-left: 10px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white; 
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

.btn-sort:hover {
  background-color: var(--sec-purp);
  color: var(--main-bg);
}

.btn-sort:focus{
  background-color: var(--prim-purp);
  color:white;
}

.btn-sort:visited{
  background-color: var(--prim-purp);
  color:white;
}

/* details page */
.resort-content-sub-container{
  margin-left:10px;
  display:flex;
  flex-wrap:wrap;
}

.flight-widget{
  width: 40%;
  margin-right: 10%; 
}

.check-section{
  margin-top: 30px;
  margin-left:10px;
}

iframe {
  border:none;
  width: 90%
}

.dest-table th, tr {
  font-size: 12px;text-align:center;border-radius: 0px;
 }

.th-dest{
 padding: 12px;
 background-color: var(--sec-blue);
 color:white;
}

.td-dest {
  padding: 12px;
  background-color: var(--thi-blue);
  color: var(--sec-bg)
}

.dest-table th:hover{
  background-color: var(--prim-blue)
}

.dest-table td:hover{
  background-color:var(--prim-purp)
}

.detail-table{
  margin-left: 10px;
  text-align:center;
  width: 80%;
  border-radius: 0px;
}

.detail-table th,td {
  padding:15px;    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  border-radius: 0px;
  cursor: default;
}

.detail-table tr:nth-child(odd){
  background-color: var(--sec-blue);
  color:white;
}

.detail-table tr:nth-child(even){
  background-color:var(--thi-blue)
}

.detail-table th:hover{
  background-color: var(--prim-blue)
}

.detail-table td:hover{
  background-color:var(--prim-purp)
}

.td-detail {
  color:var(--sec-bg)
}

.third-logo{
  margin-left: 10px;
}

.success-message{
  background-color: var(--prim-purp);
  text-align: center;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width:100%;
  min-height: 10px;  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

.resort-book{
  color: var(--main-bg)
}


/* Contact */
.MuiTextField-root {
  width: 95%!important;
}

.email-sent {
  font-weight: normal!important;
  color: var(--prim-blue);
  font-size: 12.5px;
}

/* FAQ */

.img-search {
  display: block;
  max-width:350px;
  max-height:350px;
  width: auto;
  height: auto;
}

.img-compare {
  display: block;
  max-width:350px;
  max-height:350px;
  width: auto;
  height: auto;
}
.img-book{
  display: block;
  max-width:350px;
  max-height:350px;
  width: auto;
  height: auto;
}

.howto-block {
  position: absolute;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color:white;
  border-radius: 0px;
}

.form-close {
}

.form{
  border-radius:5px;
}

.howto-container {
  display:flex;
  padding:5px;
  background-color: var(--thi-bg);
  border-radius: 0px;
}

.howto-section {
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  padding: 5px;
  background-color: white;
}

.howto-section:hover {
  background-color: var(--sec-purp)
}

.terms-doc{
  color:white;
}

.howto-p{
  margin-left:0!important;
}


.terms-text{
  margin-left: 50px;
  margin-right: 50px;
}

.terms-li {
  height: 40px;
  padding:0;
  line-height: normal;
}

.search-bar{
  padding:10px;
  margin-left: 160px;
  margin-right: 160px;
}

.resort-i {
  font-size:15px;
  font-weight: 600;
}

.desc-bullet {
  font-size: 12.5px;
  margin-bottom:10px;
}

.desc-bullet:hover{
  background-color: none;
}

.resort-btn {
  margin-top:30px;
  font-size:20px;
  width: 100%;
  background-color: var(--alt-2);
  border-color:none;
  color:white;
  height: 70px;
  border-radius: 5px;
}

.resort-btn:hover {
  background-color: var(--sec-blue);
  color:white;
}

.resort-content-skyscanner {
  background-color:var(--fou-bg);
}



@media (max-width: 1600px) {
  .form {
      margin-bottom: 100px;
  }
  .home-main {
      overflow: hidden;
  }
}
@media (max-width: 1500px) {
  .slider-beg,
  .slider-exp,
  .slider-int {
      width: 300px;
  }
  .slider-container {
      padding-bottom: 10px;
  }
  .makeStyles-formControl-1 {
      margin: 4px !important;
      font-size: 12px !important;
  }
  .MuiInputBase-input {
      font-size: 15px !important;
  }
  .MuiFormHelperText-root {
      font-size: 12px !important;
  }
}


@media (max-width: 1200px) {

  .form-submit {
      font-size: 12px;
  }
  .form-help {
      font-size: 12px;
  }
  .chart-section {
      flex-direction: column;
  }
  .graphContainer-mini {
      width: 100%;
  }
  .howto-container {
      flex-direction: column;
  }
}
@media (max-width: 1000px) {
  .form-submit {
      margin-left: 0;
  }
  .form-help {
      margin-left: 0;
  }
}

@media (max-width: 950px) {
  .site-logo-header-text{
    font-size:12.5px!important;
  }

  
}

@media (max-width: 800px) {
  .list-footer{
    height:400px;
    width:100%;
  }
  .resort-btn{
    margin-top:205px;
  }
  .resort-info-section {
    flex-direction:column;
    margin-right: 20px;
  }

  .resort-info-section-detail{
    margin-right:0;
  }


  .metric-table{
    width:100%!important;
    height: 215px!important;
  }

  .metric-table-ski{
    height: 215px;
  }

  .resort-chart-container{
    width:100%!important;
  }

  .list-header{
    margin-left:80px;
    margin-right:80px;
  }

  .site-logo {
    top: 0;
    left: 0;
}
.form {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    box-shadow: none;
    border: none;
}

.mat-select {
    width: 30px;
    font-size: 12px !important;
}
  .resort-section {
      margin-left: 80px;
      margin-right: 80px;
  }
  .MuiInputBase-input {
      font-size: 12px !important;
  }
  .MuiFormHelperText-root {
      font-size: 7.5px !important;
  }
}
@media (max-width: 600px) {
  .resort-btn{
    margin-top:75px;
    font-size: 20px;
  }
  .list-header{
    margin-left:10px;
    margin-right:10px;
    padding-left:0;
  }
  .resort-section {
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 0;
  }
  .resort-header {
      margin-bottom: 2.5px;
      height: 37.5px;
  }
  .resort-logo {
      height: 37.5px;
  }
  .resort-header-title {
      height: 37.5px;
      line-height: 37.5px;
  }
  .resort-flag {
      height: 37.5px;
  }
  .parallax {
      height: 150px;
  }
  .metric-title {
      margin-top: 5px;
  }



  .metric-card {
      margin-bottom: 5px;
      padding: 0;
      height: 20px;
  }
  .button-container {
      flex-direction: column;
      align-items: center;
  }
  .resort-book {
      margin-left: 0;
      margin-bottom: 5px;
  }
  .header-sort {
      margin-left: 10px;
      margin-right: 10px;
  }
  .resort-sort {
      margin-left: 10px;
      margin-right: 10px;
      justify-content: flex-start;
  }
  .resort-content-container:last-of-type {
      margin-bottom: 100px;
  }
  .resort-content-sub-container {
      flex-direction: column;
  }
  ul {
      margin-left: 0 !important;
      padding: 0 !important;
  }

  iframe {
      width: 80%;
  }
  .flight-widget {
      margin-bottom: 50px;
      width: 80%;
  }
  .detail-table {
      width: 95%;
  }
  tr {
      font-size: 12px;
  }
  td,
  th {
      padding: 6px !important;
  }
  .success-message {
      font-size: 15px;
  }
  .third-logo {
      width: 80%;
  }
  .email-sent {
      margin-right: 10px;
  }
  .img-search {
      display: block;
      max-width: 175px;
      max-height: 175px;
      width: auto;
      height: auto;
  }
  .img-compare {
      display: block;
      max-width: 175px;
      max-height: 175px;
      width: auto;
      height: auto;
  }
  .img-book {
      display: block;
      max-width: 175x;
      max-height: 175px;
      width: auto;
      height: auto;
  }
  .resort-ul{
    margin-left: 15px!important;
  }
  .resort-desc-container{
    margin-top: 10px;
    align-items:center;
  }

  .desc-bullet {
    margin-left: 15px!important;
  }

}

@media (max-width:600px){
  .list-footer{
    height:200px;
    width:100%;
  }
}

@media (max-width:500px){
  .form{
    background-color:var(--sec-bg);
    color:white;
    padding:2.5px;
  }

  .site-logo-header-text {
    width: auto;
    padding-bottom:25px;
  }
}
@media (max-width: 400px){


  .list-header{
    display:flex;
    flex-direction:column;
  }

  .site-logo-header {
    margin-bottom: 10px;
  }

  .site-logo-header-text {
    width: auto;
    padding-bottom:35px;
  }

  .resort-header {height: 22.5px;}
  .resort-header-title {
    font-size:18px!important;
    height: 20px;
    line-height: 20px;
  }

  .resort-logo {height: 20px}

  .resort-flag {height: 20px;}

  .metric-header {font-size: 15px!important;}

  .graph-helper-text-new{
    font-size:8px!important;
  }
}

@media (max-width: 350px) {
  .list-separator-text {
    font-size: 15px!important;
  }
  
}

@media (max-width: 250px) {
  .list-separator-text {
    font-size: 12.5px!important;
  }
}




.resort-info-section {
  display:flex;
}

.resort-logo-new {
  height: auto;
  margin-right: 10px;
  max-width: 150px;
}

.resort-flag-new {
  height: 75px;
  margin-left:auto;
  top:0;
  right:0;
}

.parallax-new {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
  border-radius: 0px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)
}

.metric-table{
  box-shadow: none;
  color:white;
  width: 40%;
  height:430px;
  margin-top:10px;
  border-spacing: 0px!important;
}

.metric-row:hover{
  outline-style: solid;
  outline-width: 400;
  outline-color:var(--prim-purp);
}

.metric-td-label{
  width:100px;
  text-align:left;
  font-size: 15px;
  font-weight: 600;
  
}

.metric-td-value{
  font-size: 15px;
  font-weight: 600;
  background-color:white;
  color:var(--main-bg)
}

.metric-td-value-1{
  background-color:var(--m1-blue);
  color:var(--main-bg)
}

.metric-td-value-2{
  background-color:var(--m2-blue);
  color:var(--main-bg)
}

.metric-td-value-3{
  background-color:var(--m3-blue);
  color:var(--main-bg)
}

.metric-td-value-4{
  background-color:var(--m4-blue);
  color:var(--main-bg)
}

.metric-td-value-5{
  font-size: 15px;
  background-color:var(--m5-blue);
  color:var(--main-bg)
}

.metric-table-ski{
  height:550px
}

.resort-chart-container {
  margin-top:10px;
  height: 550px;
  width:60%;
  background-color:var(--sec-bg)
}

.graphContainer-mini-new {
  height:200px ;
}

.graphContainer-max-new {
  height:400px ;
}

.graph-helper-text-new {
  text-align: center;
  color:white;
  font-size:10px;
}
